import { Cicon, Typography, theme } from "@imago-cloud/design-system";

import { Stack } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogPaper,
  DialogTitle,
} from "../../Core/Layouts/Dialog";
import { onErrorApp } from "../../Core/Utils/postMessage";

export const CannotBeFoundPage = () => {
  return (
    <Dialog>
      <DialogPaper sx={{ width: "880px", height: "640px" }}>
        <DialogTitle onClose={onErrorApp} isClose />
        <DialogContent>
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              paddingTop: "181px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                width: "640px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                textAlign: "center",
              }}
            >
              <Cicon
                icon="Error"
                svgProps={{ width: "36px", height: "36px" }}
              />
              <Typography variant="H24">
                Case or design cannot be found
              </Typography>
              <Typography variant="Body16" color={theme.palette.text.secondary}>
                Case or design you selected no longer exist because it was
                recently modified or deleted. Please close the window and try
                again.
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
      </DialogPaper>
    </Dialog>
  );
};
