import { Stack } from "@mui/material";
import Toast from "./Toast";
import { Cicon, Typography, theme } from "@imago-cloud/design-system";
import { IProgressToastAtom } from "./Atoms/progressToastAtom";
import LoadingDots from "../LoadingDots";

const PaymentToast = ({ status }: { status: IProgressToastAtom["status"] }) => {
  return (
    <Toast>
      {(() => {
        switch (status) {
          case "progress":
            return (
              <Typography
                variant="Subtitle_Semibold14"
                color={theme.palette.common.white}
              >
                Payment processing
                <LoadingDots />
              </Typography>
            );
          case "done":
            return (
              <Stack
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="Subtitle_Semibold14"
                  color={theme.palette.common.white}
                >
                  Payment done
                </Typography>
                <Cicon
                  icon="ReadyToDesign"
                  svgProps={{ width: "18px", height: "18px" }}
                />
              </Stack>
            );
          case "failed":
            return (
              <Stack
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="Subtitle_Semibold14"
                  color={theme.palette.common.white}
                >
                  Payment failed
                </Typography>
                <Cicon
                  icon="AlignmentRequired"
                  svgProps={{ width: "18px", height: "18px" }}
                />
              </Stack>
            );
          default:
            return null;
        }
      })()}
    </Toast>
  );
};

export default PaymentToast;
