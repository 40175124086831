import { Stack } from "@mui/material";
import Toast from "./Toast";
import { Typography, theme } from "@imago-cloud/design-system";
import LoadingDots from "../LoadingDots";
import { IProgressToastAtom } from "./Atoms/progressToastAtom";

const ExportToast = ({
  status,
  data,
}: {
  status: IProgressToastAtom["status"];
  data: any;
}) => {
  const { current, total } = data || { current: 0, total: 0 };
  const progress = current / total;
  return (
    <Toast>
      <Stack sx={{ gap: "10px" }}>
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            color: theme.palette.common.white,
          }}
        >
          <Typography variant="Subtitle_Semibold14">
            {(() => {
              switch (status) {
                case "progress":
                  return (
                    <>
                      Exporting
                      <LoadingDots />
                    </>
                  );
                case "done":
                  return "Completed";
                default:
                  return null;
              }
            })()}
          </Typography>
          <Typography variant="Body14">
            {current}/{total}
          </Typography>
        </Stack>
        <ProgressBar progress={progress} />
      </Stack>
    </Toast>
  );
};

export default ExportToast;

const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <Stack
      sx={{
        position: "relative",
        width: "100%",
        height: "8px",
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <Stack
        sx={{
          width: progress,
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: theme.palette.blue[400],
          transition: "width 1ms linear",
        }}
      />
    </Stack>
  );
};
