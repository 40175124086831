import AsyncConfirmDialogProvider from "./Compositions/AsyncConfirmDialog/AsyncConfirmDialogProvider";
import ToastProvider from "./Compositions/ProgressToast/ToastProvider";
import { Dialog } from "./Core/Layouts/Dialog";
import Routes from "./Routes";

function App() {
  return (
    <>
      <ToastProvider />
      <AsyncConfirmDialogProvider />
      <Dialog>
        <Routes />
      </Dialog>
    </>
  );
}

export default App;
