import { useQuery } from "@tanstack/react-query";
import { axios } from "../../Core/Axios/axiosInstance";
import { SETTING_API } from "../../Constants/api";
import i18next from "i18next";

export interface IUserSettingResponse {
  system: {
    language: string;
    dentalNotation: "FDI" | "ADA";
    dateFormat: "yyyy-mm-dd" | "mm-dd-yyyy" | "dd-mm-yyyy";
  };
  exportTo: {
    isAxisAccordingToCAM: boolean;
    fileFormat: "STL" | "PLY" | "OBJ" | "ORIGINAL";
  };
  network: {
    millBox: { port: string };
    dCAM: { port: string };
    alphaAI: { port: string };
  };
}

export const useSettingQuery = (enabled?: boolean) =>
  useQuery(
    ["setting-query"],
    async () => {
      const { data } = await axios
        .get<{
          success: boolean;
          data: IUserSettingResponse;
        }>(SETTING_API)
        .then(({ data }) => {
          const language = data.data.system.language || "en";
          i18next.changeLanguage(language);
          return data;
        });
      return data;
    },
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 100000,
      retry: 5,
      retryDelay: 1000,
      useErrorBoundary: true,
    }
  );
